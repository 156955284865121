"use client";
import { FC, useRef, useCallback, useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Navigation,
  Pagination,
  Autoplay,
  EffectCoverflow,
} from "swiper/modules";
import { useScreen } from "../context/ScreenContext";
import Skeleton from "react-loading-skeleton";
import Index from "./SVGIcon";
import Image from "next/image";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../navigation";
import useTracking from "./Hooks/useTracking";
import { userBonusesActiveSelector } from "../fe-core-omega/_redux/selectors/userSelectors/bonusSelectors";
import { userRolesDataSelector } from "../fe-core-omega/_redux/selectors/userSelectors";
import {
  closeLoginMenu,
  openLoginMenu,
} from "../fe-core-omega/_redux/actions/menusActions";
import { setShowSignup } from "../fe-core-omega/_redux/actions/userActions";
import { localStorageKeys } from "../fe-core-omega/meta/types/localStorage";
import { isUserAuthenticatedSelector } from "../fe-core-omega/_redux/selectors/sessionSelectors";
import {
  ICTAModel,
  IPromotionBannersResponseViewModel,
  IPromotionBannersViewModel,
} from "../fe-core-omega/meta/interfaces/promotionBanners";
import classNames from "classnames";
import { getPromotionCTA } from "@/fe-core/helpers/general";
import { filterCmsData } from "@/fe-core/helpers/utils";
import { isMobile, isTablet } from "react-device-detect";

interface Props {
  nested?: boolean;
  isPromotionPageBanner?: boolean;
  banners?: IPromotionBannersResponseViewModel | null;
  className?: string;
}
const CasinoPromotionsSlider: FC<Props> = ({
  nested,
  isPromotionPageBanner = false,
  banners,
  className = ""
}) => {
  const sliderRef = useRef<any>(null);
  const { click } = useTracking();
  const dispatch = useDispatch();
  const router = useRouter();
  const isAuthenticated = useSelector(isUserAuthenticatedSelector);
  const bonusesActive = useSelector(userBonusesActiveSelector);
  const userRoles = useSelector(userRolesDataSelector);

  const promotionBanners: IPromotionBannersViewModel[] = useMemo(() => {
    if (typeof window !== "undefined") {
      return filterCmsData(banners?.data as IPromotionBannersViewModel[]);
    } else {
      return banners?.data || [];
    }
  }, [banners, userRoles, isAuthenticated, bonusesActive]);

  const getUserStateCta = (cta: ICTAModel) => {
    const isIdentified =
      typeof window !== "undefined"
        ? localStorage.getItem(localStorageKeys.IDENTIFIED) || "0"
        : "0";

    const { redirectionLabel, redirectionLink } = getPromotionCTA({
      CTA: cta,
      isUserAuthenticated: isAuthenticated,
      isUserIdentified: isIdentified,
    });

    return { label: redirectionLabel, link: redirectionLink };
  };

  const handleCtaClick = (url: string = "") => {
    switch (url) {
      case "/register":
        dispatch(closeLoginMenu());
        dispatch(setShowSignup());
        click(
          `${isPromotionPageBanner ? "Promotion Page Banner" : "Promotion Banner"
          }`,
          {
            isRegModal: "yes",
            component: "BannerSlider - CasinoPromotionsSlider",
          }
        );
        break;
      case "/login":
        dispatch(openLoginMenu());
        click(
          `${isPromotionPageBanner ? "Promotion Page Banner" : "Promotion Banner"
          }`,
          {
            isLoginModal: "yes",
            component: "BannerSlider - CasinoPromotionsSlider",
          }
        );
        break;
      default:
        click(
          `${isPromotionPageBanner ? "Promotion Page Banner" : "Promotion Banner"
          }`,
          {
            url: url,
            navigateFrom: `${isPromotionPageBanner
              ? "Promotion Page Banner"
              : "Promotion Banner Slider"
              }`,
            component: "BannerSlider - CasinoPromotionsSlider",
          }
        );
        router.push(url);
        break;
    }
  };

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    click(`Previous Banner Button`, {
      clickedOn: isPromotionPageBanner
        ? "Promotion Page Banner"
        : "Promotion Banner",
      component: "BannerSlider - CasinoPromotionsSlider",
    });
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    click(`Next Banner Button`, {
      clickedOn: isPromotionPageBanner
        ? "Promotion Page Banner"
        : "Promotion Banner",
      component: "BannerSlider - CasinoPromotionsSlider",
    });
    sliderRef.current.swiper.slideNext();
  }, []);

  const slideCount = isPromotionPageBanner ? 1 : isMobile || isTablet ? 1.2 : 3;

  const getSliderSkeleton = () => {
    return (
      <div className="lg:grid-cols-3 grid gap-4">
        <Skeleton
          containerClassName={`skeleton-game-group grid `}
          count={1}
          className="!aspect-[384/180] !block"
          inline={true}
        />

        <Skeleton
          containerClassName={`skeleton-game-group grid  `}
          count={1}
          className="!aspect-[384/180] lg:!block hidden"
          inline={true}
        />

        <Skeleton
          containerClassName={`skeleton-game-group  `}
          count={1}
          className="!aspect-[384/180] lg:!block hidden"
          inline={true}
        />
      </div>
    );
  };

  const getNavigationButtons = () => {
    return (
      <>
        <div
          className={`absolute hidden inset-0 right-auto z-10 top-1/2 transform -translate-y-1/2 xl:block h-fit ${nested ? " -left-0 2xl:-left-[32px]" : " -left-[17px]"
            }`}
        >
          <button
            className="btn btn--square active:!shadow-none active:!ring-0 active:ring-offset-0"
            onClick={handlePrev}
          >
            <Index
              icon={"angleArrow"}
              className="w-3 h-3 text-tertiary/70 dark:text-primary-500 -ml-0.5 block transform rotate-180 "
            />
          </button>
        </div>
        <div
          className={`absolute hidden inset-0 left-auto m-auto z-10 -top-7 xl:block h-fit ${nested ? "right-0  2xl:-right-[32px]" : " -right-[17px]"
            }`}
        >
          <button
            className="btn btn--square active:!shadow-none active:!ring-0 active:ring-offset-0"
            onClick={handleNext}
          >
            <Index
              icon={"angleArrow"}
              className="block w-3 h-3 text-tertiary/70 dark:text-primary-500 -mr-0.5  "
            />
          </button>
        </div>
      </>
    );
  };

  return (
    <div
      className={classNames(
        "relative overflow-hidden md:overflow-visible md:mt-4",
        {
          "md:container": !nested,
        },
        className
      )}
    >
      {promotionBanners && promotionBanners.length > 0 && (
        <>
          {promotionBanners.length > 3 && getNavigationButtons()}
          {console.log(promotionBanners?.length, 'promotionBanners?.length')}
          <div className="relative w-full">
            <Swiper
              spaceBetween={16}
              slidesPerView={isMobile || isTablet ? 1.2 : promotionBanners?.length > 3 ? 3 : promotionBanners?.length}
              loopAddBlankSlides={false}
              centeredSlides={isMobile || isTablet ? true : false}
              className="casino-promotions"
              loop={
                false
              }
              pagination={
                isMobile || isTablet ? true : promotionBanners?.length > 3
                  ? { clickable: true }
                  : false
              }
              modules={[Navigation, Pagination, Autoplay]}
              ref={sliderRef}
              autoplay={{
                delay: 5000,
              }}
              grabCursor={true}
              breakpoints={{
                0: {
                  slidesPerView: 1.2,
                  effect: "slide",
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: promotionBanners?.length > 3 ? 3 : promotionBanners?.length,
                  effect: "slide",
                  spaceBetween: 16,
                },
              }}
            >
              {promotionBanners.map((_slide, i) => {
                const bannerImageInformation =
                  _slide &&
                  _slide.attributes &&
                  (isMobile
                    ? _slide?.attributes?.[
                      isPromotionPageBanner
                        ? "mobileImagePromotionPage"
                        : "mobileImage"
                    ]?.data?.attributes
                    : _slide?.attributes?.[
                      isPromotionPageBanner
                        ? "desktopImagePromotionPage"
                        : "desktopImage"
                    ]?.data?.attributes);
                if (!bannerImageInformation) return;
                const promotion = _slide?.attributes;
                const cta = getUserStateCta(
                  isPromotionPageBanner
                    ? _slide?.attributes?.PromotionCTA
                    : _slide?.attributes?.CTA
                );

                return (
                  <SwiperSlide
                    key={`slide_${i}_${bannerImageInformation?.url}`}
                  >
                    <div
                      className={`relative flex group items-center overflow-hidden cursor-pointer justify-center rounded aspect-[384/180] ${isPromotionPageBanner
                        ? "md:aspect-[1184/320] aspect-[358/413] "
                        : ""
                        }`}
                      onClick={() => handleCtaClick(cta?.link)}
                    >
                      <div className=" flex flex-col justify-end absolute  z-[1] inset-0 w-full p-4 bg-gradient-to-t from-black/80 via-transparent">
                        {promotion?.title && (
                          <h5 className="font-black text-white  text-sm md:text-xl drop-shadow-sm">
                            {promotion?.title}
                          </h5>
                        )}

                        {promotion?.description && (
                          <p className="text-xs md:text-sm text-white drop-shadow-sm">
                            {promotion?.description}
                          </p>
                        )}
                        {cta?.link && cta?.label && (
                          <div className="max-h-0 group-hover:max-h-10  z-[1] transition-all duration-300 overflow-hidden">
                            <button
                              onClick={() => handleCtaClick(cta?.link)}
                              className="text-white underline text-sm"
                            >
                              {cta?.label}
                            </button>
                          </div>
                        )}
                      </div>
                      <Image
                        src={
                          bannerImageInformation && bannerImageInformation?.url
                            ? bannerImageInformation?.url
                            : "/not-found.jpg"
                        }
                        fill
                        alt={
                          bannerImageInformation &&
                            bannerImageInformation?.alternativeText
                            ? bannerImageInformation?.alternativeText
                            : "promotions-banner"
                        }
                        className="w-full h-full bg-cover object-cover object-center group-hover:scale-105 transition-all duration-300"
                        priority={false}
                        loading="lazy"
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </>
      )}
      {!promotionBanners && getSliderSkeleton()}
    </div>
  );
};

export default CasinoPromotionsSlider;
