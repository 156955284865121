import { AFFILIATE_MARKER_QUERY_KEYS } from "@/config/affiliate";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { NextRequest } from "next/server";
import requestIp from "request-ip";
import { IBonusViewModel, IRoleViewModel } from "../meta/interfaces/user";
import { localStorageKeys } from "../meta/types/localStorage";
import { decryptedJsonData, decryptedStringData } from "./general";
import { CookiesStorage } from "./storage";
import { EXPIRED_BONUS_LIST_STATUS } from "@/config/bonus";

dayjs.extend(utc);
dayjs.extend(timezone);

// cleanup: remove
export function getNWAHeaders(
  req: NextRequest,
  headersObj: { [key: string]: string | number }
): Headers {
  let ip = req.headers.get("cf-connecting-ip");
  if (!ip) {
    ip = requestIp.getClientIp(req);
  }
  if (ip === "::1") {
    ip = "94.52.153.234";
  }
  return new Headers({
    "NWA-Client-Ip": ip || "",
    ...headersObj,
  });
}

export function getNWAHeadersIP(
  req: NextRequest,
): string {
  let ip = req.headers.get("cf-connecting1-ip");
  //@ts-ignore
  if (!ip) ip = requestIp.getClientIp(req)
  if (ip === "::1") ip = "94.52.153.234";

  return ip || null;
}

// DEPRECATED
function getHeaders(headers: Headers = new Headers()): Headers {
  const tokenB64 = Buffer.from(
    process.env.GTSBT_USERNAME + ":" + process.env.GTSBT_PASSWORD
  ).toString("base64");
  const token = "Basic " + tokenB64;
  headers.set("Authorization", token);

  return headers;
}
export default getHeaders;

export function replaceLocale(url: string): string {
  // Split the URL by slashes to manipulate individual parts
  const parts = url.split("/");

  // Check if the second part of the URL is a locale (e.g., 'en', 'fr', etc.)
  // If it is, remove it from the array
  if (parts[1].match(/^[a-z]{2}$/)) {
    parts.splice(1, 1);
  }

  // Rejoin the parts back into a complete URL
  return parts.join("/");
}

export function utctoLocal(date: string | Date): Dayjs {
  return dayjs.utc(date).tz(dayjs.tz.guess());
}

export function getUrlParts(url: string): {
  href: string;
  host: string;
  hostname: string;
  port: string;
  pathname: string;
  protocol: string;
  hash: string;
  search: string;
} {
  const a = document.createElement("a");
  a.href = url;

  return {
    href: a.href,
    host: a.host,
    hostname: a.hostname,
    port: a.port,
    pathname: a.pathname,
    protocol: a.protocol,
    hash: a.hash,
    search: a.search,
  };
}

export const parseAffiliateTag = (
  tag: string
): { parent_id: string; campaign_id: string } | null => {
  console.log("parseAffiliateTag", tag);
  const values = tag.split(":");
  return values.length === 2 && values[0] && values[1]
    ? {
      campaign_id: values[0],
      parent_id: values[1],
    }
    : null;
};

//@ts-ignore
export const allowAddEntry = (list: String[] = [], cmsTags = [], key) => {
  list = list.length === 1 && list[0] === "" ? [] : list;
  let includes;
  let excludes;

  if (!cmsTags || cmsTags.length === 0) {
    return true;
  } else {
    cmsTags.forEach((item) => {
      //@ts-ignore
      switch (item.type) {
        case "Include":
          //@ts-ignore
          if (!item?.[key] || item?.[key]?.length === 0) {
            includes = true;
            break;
          }
          //@ts-ignore
          item?.[key]?.forEach((taglist) => {
            if (list?.includes(taglist.name)) {
              includes = true;
            }
          });
          break;
        case "Exclude":
          //@ts-ignore
          if (!item?.[key] || item?.[key]?.length === 0) {
            excludes = false;
            break;
          }
          //@ts-ignore
          item[key]?.forEach((taglist) => {
            if (list?.includes(taglist.name)) {
              excludes = true;
            } else {
              excludes = false;
            }
          });
          break;
      }
    });
  }

  if (includes && excludes) {
    return false;
  } else if (includes) {
    return true;
  } else if (excludes === false) {
    return true;
  } else {
    return false;
  }
};

export const filterCmsData = (data: any[]) => {
  try {
    const rolesData: IRoleViewModel[] = decryptedJsonData(localStorageKeys.PROFILE_DATA)?.tags;
    const bonusData: IBonusViewModel = decryptedJsonData(localStorageKeys.BONUS_DATA);

    const key = AFFILIATE_MARKER_QUERY_KEYS.find(
      (affiliateMarkerQueryKey) =>
        !!CookiesStorage.getItem(affiliateMarkerQueryKey)
    );

    const isIdentified =
      localStorage.getItem(localStorageKeys.IDENTIFIED) ? "1" : "0";
    const isAuthenticated = decryptedStringData(localStorageKeys.IS_LOGGED_IN) === "true"
    const btag = CookiesStorage.getItem(key as string);
    const btagCodes = btag ? [btag] : [];
    const userRoles = rolesData?.map((x) => x.roleName) || [];
    const bonusCodes =
      bonusData?.bonuses
        ?.filter((bonus) => !EXPIRED_BONUS_LIST_STATUS.includes(bonus?.status))
        ?.map((_bonus) => _bonus.bonusPlanId?.toString()) || [];
    const currentDay = new Date().toLocaleDateString("en-US", {
      weekday: "long",
    });

    const filteredData = data?.filter(item => {
      const {
        userState,
        userRole,
        bonusCode = [],
        btagCode,
        publishDays,
      } = item?.attributes || {};

      if (publishDays && !publishDays.includes(currentDay)) return false;

      if (isAuthenticated) {
        if (userState && !userState.includes("Authenticated")) {
          return false;
        }
        if (!allowAddEntry(userRoles, userRole, "roleId")) {
          return false
        }
        if (!allowAddEntry(bonusCodes, bonusCode, "code")) {
          return false
        }
        if (!allowAddEntry(btagCodes, btagCode, "btag")) {
          return false
        }
        return true;
      } else if (!!+isIdentified) {
        if (!allowAddEntry(btagCodes, btagCode, "btag")) {
          return false
        }
        if (userState && !userState.includes("Identified")) {
          return false;
        }
      } else {
        if (!allowAddEntry(btagCodes, btagCode, "btag")) {
          return false
        }
        if (userState && !userState.includes("Anonymous")) {
          return false;
        }
      }
      return true;
    });
    const maxRank = filteredData
      ? Math.max(...filteredData?.map((obj) => parseInt(obj?.attributes?.rank || 0)))
      : 1;

    const sortedData = filteredData
      .map((data) => ({
        ...data,
        attributes: {
          ...data?.attributes,
          rank: parseInt(data?.attributes?.rank || maxRank) + 1,
        },
      }))
      .sort((a, b) => {
        if (!a.attributes.rank) {
          return 1;
        }
        if (!b.attributes.rank) {
          return -1;
        }
        return a.attributes.rank - b.attributes.rank;
      });

    return sortedData;
  } catch (error) {
    console.log("err--err--filteredData", error);
    return data
  }
}
